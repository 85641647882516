import VueFocus from 'vue-focus';
import isTouchDevice from 'is-touch-device';
import vueButton from '@ui-common/vue-button';
export default {
	mixins: [VueFocus.mixin],
	data: () => ({
		openedMenu: null,
		focusedMenu: null,
		menuTimer: null,
		activeMenuId: null
	}),
	components: {
		vueButton
	},
	methods: {
		openMenu(menuId, hover, e) {
			// tablet eseten nincs lenyilas
			if (isTouchDevice() && !hover) {
				let href = e.currentTarget.getAttribute('href');
				window.location.href = href;
			} else if (menuId == this.openedMenu) {
				if (!hover) {
					let href = e.currentTarget.getAttribute('href');
					window.location.href = href;
				}
			} else {
				clearTimeout(this.menuTimer);
				if (hover) {
					this.menuTimer = setTimeout(() => {
						this.openedMenu = menuId;
					}, 250);
					this.focusedMenu = null;
				} else {
					this.openedMenu = menuId;
				}
			}
		},
		focusMenu(menuId) {
			if (this.openedMenu != null) {
				this.openedMenu = menuId;
			}
			this.focusedMenu = menuId;
		},
		closeMenu() {
			clearTimeout(this.menuTimer);
			this.openedMenu = null;
			this.focusedMenu = null;
		},
		isMenuOpened(menuId) {
			return this.openedMenu == menuId;
		}
	},
	mounted() {
		this.activeMenuId = window.context.activeMenuId;
	}
};
