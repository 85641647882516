let lowerWithoutAccent = (string) => {
	let lowerString = string.toLowerCase();
	lowerString = lowerString.trim().replace(/\s+/g, ' ');
	lowerString = lowerString.replace(/\s/g, '_');
	let result = '';
	for (let i = 0; i < lowerString.length; i++) {
		let char = lowerString.charAt(i);
		if ('àáâãäåæ'.indexOf(char) > -1) {
			result += 'a';
		} else if ('èéêë'.indexOf(char) > -1) {
			result += 'e';
		} else if ('òóôõöő'.indexOf(char) > -1) {
			result += 'o';
		} else if ('ùúûüű'.indexOf(char) > -1) {
			result += 'u';
		} else if ('ìíîï'.indexOf(char) > -1) {
			result += 'i';
		} else {
			result += char;
		}
	}
	return result;
};

export default lowerWithoutAccent;
