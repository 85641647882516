import MegaMenu from './mega-menu/mega-menu';
import { isMobile } from '@ui-common/sf-js-utils';
export default {
	mixins: [isMobile],
	data: () => ({
		service: null,
		toggledSection: null,
		activeMenuId: null
	}),
	components: {
		MegaMenu
	},
	computed: {
		searchbarVisible() {
			return this.toggledSection == 'search';
		},
		navigationbarVisible() {
			return this.toggledSection == 'navigation';
		}
	},
	methods: {
		goToUrl(e) {
			window.location.href = e.target.value;
		},
		searchToggle() {
			this.toggledSection = this.searchbarVisible ? null : 'search';
			if (this.searchbarVisible) {
				this.$nextTick(() => {
					this.$refs['main-search'].$refs['search-field'].$refs['search-input'].focus();
				});
			}
			if (this.searchbarVisible) document.body.classList.add('modal-open');
			else document.body.classList.remove('modal-open');
		},
		navigationToggle() {
			this.toggledSection = this.navigationbarVisible ? null : 'navigation';
			if (this.navigationbarVisible) document.body.classList.add('modal-open');
			else document.body.classList.remove('modal-open');
		}
	},
	mounted() {
		this.activeMenuId = window.context.activeMenuId;
	}
};
