import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueI18n from 'vue-i18n';
import mainMenu from '../frame/header-menu/header-menu.js';
import VueModal from '@ui-common/vue-modal';
import VueTooltip from '@ui-common/vue-tooltip';
import VueLiveAnnouncer from '@ui-common/vue-live-announcer';
import VueTab from '@ui-common/vue-tab';
import VueButton from '@ui-common/vue-button';
import VueScrollTo from 'vue-scrollto';
import { VueAccordion, VueAccordionItem } from '@ui-common/vue-accordion';
import PortalVue from 'portal-vue';
import store from '../common/js/vuex/store';
import 'wicg-inert';
import 'focus-visible';
import { getSiblings } from '@ui-common/sf-js-utils';
import moment from 'moment';
import VueGtm from 'vue-gtm';

Vue.use(Vuelidate);
Vue.use(VueScrollTo);
Vue.use(VueI18n);
Vue.use(VueLiveAnnouncer);
Vue.use(VueModal);
Vue.use(PortalVue);
Vue.use(VueGtm, {
	id: window.context.gtmId
});

Vue.config.productionTip = false;

Vue.component('main-menu', mainMenu);
Vue.component('VueTooltip', VueTooltip);
Vue.component('VueTab', VueTab);
Vue.component('VueAccordion', VueAccordion);
Vue.component('VueAccordionItem', VueAccordionItem);
Vue.component('VueButton', VueButton);

const i18n = new VueI18n({
	locale: 'hu'
});

moment.locale('hu');

export default {
	i18n,
	store,
	mounted() {
		this.$modalEventBus.$on('toggle-inert', (isInert) => {
			const modalContainer = document.querySelector('#modal-content');
			const siblings = getSiblings(modalContainer);
			Object.keys(siblings).forEach((key) => {
				siblings[key].inert = isInert;
			});
		});
	}
};
