<template lang="pug">
VueFeedback(type="error")
	template(slot="feedback-title") Technikai hiba történt
</template>

<script>
import VueFeedback from '@ui-common/vue-feedback';
export default {
	components: {
		VueFeedback
	}
};
</script>
