<template lang="pug">
.video-player__recommended
	.video-player__recommended-wrapper 
		h4.video-player__recommended__title {{ recommendedTitle }}
			span.sr-only {{ recommendedTitleSrOnly }}
			div(aria-live="polite", aria-atomic="true")
				span.sr-only {{ firstVisibleVideoIndex + 1 }}. és {{ firstVisibleVideoIndex + 2 }}. videó az {{ videos.length }} ajánlott videóból
		.video-player__recommended-list-container
			.video-player__recommended-list-wrapper
				ul(ref="recommended-video-ul")
					li.video-player__recommended-list-item(v-for="(video, index) in videos", @click="selectVideo(index)", v-if="index === firstVisibleVideoIndex || index === firstVisibleVideoIndex + 1 || isMobile")
						button
							.video-player__recommended-img-wrap
								img(:src="swImagePath + video.image", alt="")
							span {{ video.title }}

			button.btn-prev(:class="{ inactive: isPreviousButtonDisabled }", :disabled="isPreviousButtonDisabled", @click="previousStep")
				span.sr-only Előző videók
				svg(width="48", height="48", viewbox="0 0 48 48", fill="none", xmlns="http://www.w3.org/2000/svg")
					rect(width="48", height="48", rx="24")
					path(d="M26 18L20 24L26 30", stroke="white", stroke-width="2", stroke-linecap="round", stroke-linejoin="round")

			button.btn-next(:class="{ inactive: isNextVideoButtonDisabled }", :disabled="isNextVideoButtonDisabled", @click="nextStep")
				span.sr-only Következő videók
				svg(width="48", height="48", viewbox="0 0 48 48", fill="none", xmlns="http://www.w3.org/2000/svg")
					rect(x="48", y="48", width="48", height="48", rx="24", transform="rotate(-180 48 48)")
					path(d="M22 30L28 24L22 18", stroke="white", stroke-width="2", stroke-linecap="round", stroke-linejoin="round")
</template>
<script>
import { required } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
			firstVisibleVideoIndex: 0
		};
	},
	props: {
		videos: {
			required
		},
		swImagePath: {
			required
		},
		recommendedTitle: {
			required
		},
		recommendedTitleSrOnly: {
			required
		},
		autoPlayVideoIndex: {
			required
		},
		autoplay: {
			required
		}
	},
	methods: {
		selectVideo(index) {
			this.setFirstVisibleVideoIndex(index);
			this.$emit('on-select-recommended-video', index);
		},
		nextStep() {
			++this.firstVisibleVideoIndex;
		},
		previousStep() {
			--this.firstVisibleVideoIndex;
		},
		setFirstVisibleVideoIndex(index) {
			if (this.isMobile || index == this.videos.length - 1 || this.autoplay) {
				this.firstVisibleVideoIndex = index == this.videos.length - 1 ? index - 1 : index;
			}
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		isPreviousButtonDisabled() {
			return this.firstVisibleVideoIndex == 0;
		},
		isNextVideoButtonDisabled() {
			return this.firstVisibleVideoIndex == this.videos.length - 2;
		}
	},
	watch: {
		autoPlayVideoIndex(index) {
			this.setFirstVisibleVideoIndex(index);
		}
	}
};
</script>
<style lang="scss">
@import '~@ui-common/sf-style-utils/lib/helpers/_colors.scss';
@import '~@ui-common/sf-style-utils/lib/helpers/_queries.scss';

.video-player__recommended {
	margin: 32px 0 0;
}

.video-player__recommended__title {
	font-size: 18px;
	margin-bottom: 24px;
}

.video-player__recommended-wrapper {
	@include media('>=tablet') {
		background-color: white;
		border-radius: 24px;
		padding: 32px;
	}
}

.video-player__recommended-wrapper > h4 {
	text-align: left;
}

.video-player__recommended-list-container {
	position: relative;
}

.video-player__recommended-list-container > .btn-next,
.video-player__recommended-list-container > .btn-prev {
	display: none;
	z-index: 4;

	@include media('>=tablet') {
		display: block;
		height: 48px;
		position: absolute;
		top: calc(50% - 28px);
		width: 48px;
	}

	svg {
		fill: $ui-brand;
		transition: .2s;
	}

	&:hover svg,
	&:focus svg {
		cursor: pointer;
		fill: $ui-brand-hover;
	}

	&[disabled='disabled'] svg {
		fill: $text-inactive;
		pointer-events: none;
	}
}

.video-player__recommended-list-container > .btn-prev {
	left: -20px;

	@include media('>=desktop') {
		left: 0;
	}
}

.video-player__recommended-list-container > .btn-next {
	right: -20px;

	@include media('>=desktop') {
		right: 0;
	}
}

.video-player__recommended-list-wrapper {
	max-height: 136px;

	@include media('<tablet') {
		margin-right: -20px;
		overflow-x: auto;
	}
}

.video-player__recommended-list-wrapper ul {
	display: inline-flex;
	margin-bottom: 48px;

	@include media('>=tablet') {
		margin-bottom: 0;
		width: 100%;
	}

	@include media('>=desktop') {
		margin: 0 auto;
		max-width: 660px;
	}
}

.video-player__recommended-list-item {
	background-color: white;
	border-radius: 16px;
	box-shadow: 0 16px 48px rgba(73, 92, 136, .15);
	font-weight: bold;
	margin-right: 16px;
	min-width: 270px;
	width: 100%;

	@include media('>=tablet') {
		min-width: auto;

		&:last-of-type {
			margin-right: 0;
		}
	}

	@include media('>=desktop') {
		margin-right: 32px;
	}
}

.video-player__recommended button {
	align-items: center;
	background: none;
	border: none;
	display: flex;
	height: 100%;
	margin: 0;
	padding: 0;
	text-align: left;
	width: 100%;

	&:hover .video-player__recommended-img-wrap::before {
		opacity: .1;
	}
}

.video-player__recommended button span {
	color: $text-primary;
	flex: 1;
	padding: 5px 15px 5px 15px;

	@include media('>=desktop') {
		padding: 5px 20px 5px 30px;
	}
}

.video-player__recommended-img-wrap {
	border-radius: 16px;
	height: 120px;
	overflow: hidden;
	position: relative;
	width: 120px;

	@include media('>=desktop') {
		height: 136px;
		width: 136px;
	}

	&::before {
		background-color: black;
		border-radius: 16px;
		content: '';
		height: 100%;
		left: 0;
		opacity: .2;
		position: absolute;
		top: 0;
		transition: .2s;
		width: 100%;
		z-index: 1;
	}

	&::after {
		border-bottom: 16px solid transparent;
		border-left: 26px solid white;
		border-top: 16px solid transparent;
		content: '';
		height: 0;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 0;
		z-index: 2;
	}
}

.video-player__recommended-img-wrap img {
	height: 100%;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 0;
}
</style>
