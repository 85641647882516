<template lang="pug">
// https://github.com/kaorun343/vue-youtube-embed
.video-player-youtube
	.video-player-youtube__container(v-if="showPlayer")
		youtube(:video-id="video.videoId", player-width="380", player-height="220", @ended="videoPlayingEnded", @ready="videoPlayerLoaded", :player-vars="{ autoplay: autoplay }", ref="youtube-player", v-show="showVideo")
	.video-player-youtube__loader
		.loader
			.loader__inner
				.loader__gap
				.loader__left
					.loader__half-circle
				.loader__right
					.loader__half-circle
</template>
<script>
import { required } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
			showPlayer: false,
			autoplay: 0,
			initLoad: true,
			showVideo: false
		};
	},
	components: {},
	props: {
		video: {
			required
		},
		modalId: {}
	},
	computed: {},
	watch: {
		video() {
			this.showPlayer = false;
			this.showVideo = false;
			this.$nextTick(() => {
				this.showPlayer = true;
			});
		}
	},
	methods: {
		videoPlayingEnded() {
			this.autoplay = 1;
			this.$emit('on-play-next-video');
		},
		videoPlayerLoaded() {
			this.showVideo = true;
			// akadálymentes móka
			if (this.$refs['youtube-player'] && this.$refs['youtube-player'].$el && this.$refs['youtube-player'].$el.querySelector('iframe')) {
				let iframeElem = this.$refs['youtube-player'].$el.querySelector('iframe');
				iframeElem.setAttribute('name', 'Youtube videó');
				if (!this.initLoad) {
					iframeElem.focus();
				}
				this.initLoad = false;
			}
		}
	},
	mounted() {
		this.showPlayer = true;
		this.$modalEventBus.$on('modal-closed', (modalId) => {
			if (modalId == this.modalId) {
				this.autoplay = 0;
				this.showPlayer = false;
			}
		});
		this.$modalEventBus.$on('modal-opened', (modalId) => {
			if (modalId == this.modalId) {
				this.showPlayer = true;
				this.initLoad = true;
			}
		});
	}
};
</script>
<style lang="scss" scoped>
@import '~@ui-common/sf-style-utils/lib/helpers/_queries.scss';

.video-player-youtube {
	background-color: black;
	margin: 0 auto;
	max-width: 780px;
	min-height: 171px;
	position: relative;
	width: 100%;

	@include media('>=desktop') {
		min-height: 252px;
	}
}

.video-player-youtube__container {
	position: relative;
	z-index: 2;
}

.video-player-youtube__loader {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50px);
	z-index: 1;
}
</style>
