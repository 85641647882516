<template lang="pug">
.video-player__playlist
	accordion(accordion, v-cloak, v-if="isMobile && videos.length > 3")
		accordion-item(aria-controls="video-playlist")
			template(slot="title")
				span.video-player__accordion-title {{ playlistTitle }}
				span.video-player__accordion-label {{ videos.length }} videó
				span.sr-only lejátszási listája
			#video-playlist.video-player__playlist-container
				ul.video-player__playlist-list
					li.video-player__playlist-list-item(v-for="(video, index) in videos", :aria-current="'' + selectedVideoIndex == index", @click="selectVideo(index)")
						button(:class="{ active: selectedVideoIndex == index }")
							svg.video-player__play-icon(width="24", height="24", viewbox="0 0 24 24", fill="none", xmlns="http://www.w3.org/2000/svg")
								path(d="M19.7782 4.22177C24.0739 8.51753 24.0739 15.4823 19.7782 19.7781C15.4824 24.0739 8.5176 24.0739 4.22183 19.7781C-0.0739423 15.4823 -0.0739423 8.51753 4.22183 4.22177C8.5176 -0.0740033 15.4824 -0.0740033 19.7782 4.22177", stroke-width="2", stroke-linecap="round", stroke-linejoin="round")
								path(d="M9.00001 15.613V15.613C9.00077 16.5165 9.73385 17.2484 10.6374 17.2476C11.0326 17.2473 11.4143 17.1039 11.712 16.844L17.25 12L11.712 7.15297V7.15297C11.0326 6.55816 9.99965 6.62673 9.40484 7.30613C9.14367 7.60444 8.9998 7.98749 9 8.38397L9.00001 15.613Z", stroke-width="2", stroke-linecap="round", stroke-linejoin="round")
							span {{ index + 1 + '. ' + video.title }}
							span.sr-only Hossza: {{ video.length.split(':')[0] }} perc {{ video.length.split(':')[1] }} másodperc.
	.video-player__playlist-container(v-else, ref="video-playlist")
		h4.sr-only {{ playlistTitle }}
			span.video-player__accordion-label {{ videos.length }} videó lejátszási listája
		ul#video-playlist.video-player__playlist-list(ref="video-playlist-list")
			li.video-player__playlist-list-item(v-for="(video, index) in videos", :aria-current="'' + selectedVideoIndex == index", @click="selectVideo(index)")
				button(:class="{ active: selectedVideoIndex == index }")
					svg.video-player__play-icon(width="24", height="24", viewbox="0 0 24 24", xmlns="http://www.w3.org/2000/svg", fill="none")
						path(d="M19.7782 4.22177C24.0739 8.51753 24.0739 15.4823 19.7782 19.7781C15.4824 24.0739 8.5176 24.0739 4.22183 19.7781C-0.0739423 15.4823 -0.0739423 8.51753 4.22183 4.22177C8.5176 -0.0740033 15.4824 -0.0740033 19.7782 4.22177", stroke-width="2", stroke-linecap="round", stroke-linejoin="round")
						path(d="M9.00001 15.613V15.613C9.00077 16.5165 9.73385 17.2484 10.6374 17.2476C11.0326 17.2473 11.4143 17.1039 11.712 16.844L17.25 12L11.712 7.15297V7.15297C11.0326 6.55816 9.99965 6.62673 9.40484 7.30613C9.14367 7.60444 8.9998 7.98749 9 8.38397L9.00001 15.613Z", stroke-width="2", stroke-linecap="round", stroke-linejoin="round")
					span {{ index + 1 + '. ' + video.title }}
					span.sr-only Hossza: {{ video.length.split(':')[0] }} perc {{ video.length.split(':')[1] }} másodperc.
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { VueAccordion, VueAccordionItem } from '@ui-common/vue-accordion';

export default {
	data() {
		return {};
	},
	props: {
		videos: {
			required
		},
		selectedVideoIndex: {
			required
		},
		playlistTitle: {
			required
		}
	},
	methods: {
		selectVideo(index) {
			this.$emit('on-video-select', index);
		},
		scrollPlaylist(index) {
			if (!this.isMobile) {
				let elem = this.$refs['video-playlist-list'].querySelectorAll('li')[index];
				this.$scrollTo(elem, { duration: 500, container: this.$refs['video-playlist'] });
			}
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	components: {
		VueAccordion,
		VueAccordionItem
	},
	watch: {
		selectedVideoIndex(value) {
			this.scrollPlaylist(value);
		}
	}
};
</script>
<style lang="scss">
@import '~@ui-common/sf-style-utils/lib/helpers/_colors.scss';
@import '~@ui-common/sf-style-utils/lib/helpers/_queries.scss';

.video-player__playlist {
	border-radius: 24px;
	box-shadow: 0 16px 48px rgba(73, 92, 136, .15);
	overflow: hidden;
	padding: 16px;
	position: relative;
}

.video-player__playlist-container {
	max-height: 218px;
	overflow-y: auto;
}

.video-player__playlist-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.video-player__playlist-list-item button {
	align-items: center;
	background: none;
	border: none;
	border-radius: 32px;
	color: $text-primary;
	display: flex;
	padding: 8px;
	text-align: left;
	transition: .2s;
	width: 100%;

	@include media('>=desktop') {
		padding: 16px;
	}

	&:not(.active):hover {
		cursor: pointer;
		opacity: .8;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	&.active {
		background-color: $ui-warning;
		color: $text-primary;
		cursor: default;

		svg {
			stroke: white;
		}
	}
}

.video-player__play-icon {
	margin-right: 8px;
	stroke: $text-secondary;
}
</style>
