import Vue from 'vue';
import VueTouch from 'vue-touch';
import { isMobile } from '@ui-common/sf-js-utils';
Vue.use(VueTouch);
export default {
	mixins: [isMobile],
	data() {
		return {
			currentColumn: 0,
			cardWidth: null,
			deviceWidth: null
		};
	},
	props: {
		packages: {
			type: Array,
			required: false
		}
	}
};
