<template lang="pug">
VueFeedback(type="no-result")
	template(slot="feedback-title") Nincs a keresési feltételeknek megfelelő elem
</template>

<script>
import VueFeedback from '@ui-common/vue-feedback';
export default {
	components: {
		VueFeedback
	}
};
</script>
