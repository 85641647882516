<template lang="pug">
.video-player__main-wrapper
	.video-player__video-block(:class="{ 'video-player__video-block--gray': recommendedMode, 'playlist-mode': playlistMode }")
		div(v-if="!showFulltext", :class="{ 'playlist-mode__container': playlistMode }")
			.video-player__youtube-container(:class="{ 'playlist-mode__item': playlistMode }")
				video-player-youtube(:video="currentVideo", @on-play-next-video="playNextVideo", :modal-id="modalId")
				a.sf-link(v-if="fullTextMode", href="", @click.prevent="showFulltext = true") {{ fulltextLinkTitle }}
			div(v-if="!recommendedMode", :class="{ 'playlist-mode__item': playlistMode }")
				video-player-playlist(:videos="videos", :selected-video-index="selectedVideoIndex", v-if="playlistMode", @on-video-select="changeCurrentVideo", :playlist-title="playlistTitle")
			video-player-recommended(v-if="recommendedMode", :videos="recommendedVideos", :sw-image-path="swImagePath", @on-select-recommended-video="playRecommendedVideo", :recommended-title="recommendedTitle", :recommended-title-sr-only="recommendedTitleSrOnly", :auto-play-video-index="selectedRecommendedVideoIndex", :autoplay="autoplay")
		div(v-if="showFulltext && fullTextMode")
			video-player-full-text(:full-text="videoFullText")
</template>
<script>
import videoPlayerYoutube from './video-player-youtube/video-player-youtube.vue';
import videoPlayerPlaylist from './video-player-playlist/video-player-playlist.vue';
import videoPlayerRecommended from './video-player-recommended/video-player-recommended.vue';
import videoPlayerFullText from './video-player-full-text/video-player-full-text.vue';
import { required } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
			showFulltext: false,
			selectedVideoIndex: 0,
			recommendedVideoSelected: false,
			selectedRecommendedVideoIndex: 0,
			autoplay: false
		};
	},
	props: {
		videos: {
			required
		},
		recommendedVideos: {
			default: []
		},
		swImagePath: {
			required
		},
		startIndex: {
			default: 0
		},
		modalId: {},
		recommendedTitle: {
			required
		},
		recommendedTitleSrOnly: {
			required
		},
		playlistTitle: {
			required
		},
		fulltextLinkTitle: {},
		fullTextMode: {
			required
		}
	},
	components: {
		videoPlayerYoutube,
		videoPlayerPlaylist,
		videoPlayerRecommended,
		videoPlayerFullText
	},
	watch: {
		startIndex(value) {
			this.selectedVideoIndex = value;
		}
	},
	computed: {
		recommendedMode() {
			return this.recommendedVideos.length !== 0;
		},
		singleVideoMode() {
			return !this.recommendedMode && this.videos.length === 1;
		},
		playlistMode() {
			return !this.recommendedMode && !this.singleVideoMode;
		},
		currentVideo() {
			return this.recommendedVideoSelected ? this.recommendedVideos[this.selectedRecommendedVideoIndex] : this.videos[this.selectedVideoIndex];
		},
		videoFullText() {
			return this.recommendedVideoSelected
				? this.recommendedVideos[this.selectedRecommendedVideoIndex].fullText
				: this.videos[this.selectedVideoIndex].fullText;
		}
	},
	methods: {
		changeCurrentVideo(index) {
			this.autoplay = false;
			this.selectedVideoIndex = index;
		},
		playNextVideo() {
			if (this.recommendedVideoSelected) {
				this.selectedRecommendedVideoIndex = ++this.selectedRecommendedVideoIndex % this.recommendedVideos.length;
			} else {
				this.selectedVideoIndex = ++this.selectedVideoIndex % this.videos.length;
			}
			this.autoplay = true;
		},
		playRecommendedVideo(index) {
			this.autoplay = false;
			this.recommendedVideoSelected = true;
			this.selectedRecommendedVideoIndex = index;
		}
	},
	mounted() {
		this.$modalEventBus.$on('modal-closed', (modalId) => {
			if (modalId == this.modalId) {
				this.recommendedVideoSelected = false;
				this.showFulltext = false;
				this.selectedRecommendedVideoIndex = 0;
			}
		});
		this.selectedVideoIndex = 0;
	}
};
</script>
<style lang="scss">
@import '~@ui-common/sf-style-utils/lib/helpers/_colors.scss';
@import '~@ui-common/sf-style-utils/lib/helpers/_queries.scss';
@import '~@ui-common/sf-style-utils/lib/helpers/_mixins.scss';

.video-player__video-block {
	margin-bottom: 30px;

	@include media('>=tablet') {
		margin-bottom: 48px;
	}
}

.video-player-full-text h1,
.video-player-full-text h2,
.video-player-full-text h3,
.video-player-full-text h4 {
	&:first-child {
		padding-right: 30px;
	}
}

.video-player__video-block--gray {
	text-align: center;

	@include media('>=tablet') {
		background-color: $contentBg-gray-3;
		border-radius: 24px;
		padding: 24px;
	}

	@include media('>=desktop') {
		padding: 32px;
	}
}

.video-player__youtube-container {
	@include media('>=desktop') {
		margin: 0 auto;
		max-width: 448px;
	}
}

.mvml .video-player__youtube-container .sf-link {
	display: block;
	margin: 8px auto 0;
	padding: 0 16px;
	text-align: left;

	@include media('>=tablet') {
		padding: 0;
	}

	@include media('>=desktop') {
		margin-top: 16px;
	}
}

.playlist-mode .video-player__youtube-container {
	@include media('<desktop') {
		display: block;
		margin-bottom: 24px;
		padding: 0;
	}
}

.playlist-mode__container {
	@include media('>=desktop') {
		display: flex;
		margin: 0 -16px;
	}
}

.playlist-mode__item {
	@include side-pull();

	@include media('>=desktop') {
		flex-grow: 1;
		margin: 0 16px;
		width: calc(50% - 32px);
	}
}

.video-player-youtube__container > div {
	height: 0;
	max-width: 100%;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
}

.video-player__video-block .video-player__youtube-container iframe {
	height: 100%;
	left: 50%;
	position: absolute;
	top: 0;
	transform: translateX(-50%);
	width: 100%;
}

.video-player__playlist .el-collapse,
.video-player__playlist .el-collapse-item.is-active {
	margin-bottom: 0;
}

.video-player__playlist .el-collapse-item .el-collapse-item-header button {
	padding: 20px 44px 20px 20px;
}

.video-player__playlist .video-player__accordion-title,
.video-player__playlist .video-player__accordion-label {
	font-size: 16px;
	line-height: 1.25;
}

.video-player__playlist .el-collapse-item .el-collapse-item-header button > img {
	right: 14px;
}

.video-player__playlist .el-collapse-item .el-collapse-item-content {
	background-color: transparent;
	border: none;
	padding-top: 12px;
	position: relative;
}

.video-player__playlist .el-collapse-item .el-collapse-item-content .video-player__playlist-container {
	max-height: none;
}

.video-player-youtube + .sf-link {
	display: inline-block;
	margin-top: 8px;

	@include media('>=tablet') {
		margin-top: 16px;
	}
}
</style>
